
import { Component, Vue, Provide, Emit, Prop, Watch } from 'vue-property-decorator'
import { mobileRegExp } from '@/utils/reg'
import smsMixin from '@/mixins/sms'
import httpHelper from '@/utils/request'
import InputItem from '@/components/InputItem.vue'
import configs from '@/configs'
import Authorization from '@/components/authorization.vue'
import VerificationCode from './VeriCode.vue'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import { getGoBackUrl, removeGoBackUrl } from '@/utils/gobackurl'
import PactDialog from '@/components/PactDialog.vue'

@Component({
  name: 'passportSMS',
  components: {
    'input-item': InputItem,
    'pact-dialog': PactDialog,
    'authorization': Authorization,
    'verification-code': VerificationCode
  },
  mixins: [smsMixin, GlobalMixin]
})
export default class PassportUnionSMSPage extends Vue {
  @Provide() public isFirst: boolean = true
  @Provide() public mobile: string = ''
  @Provide() public dialog: boolean = false
  @Provide() public p: string = ''
  @Provide() public policySelect: boolean = false
  @Provide() public smsType: number = 3 // smsType: 4代表是从良储公众号过来的链接
  @Provide() public grantType: number = 4
  @Provide() public iframeUrl: string = ''
  @Provide() public storeCode: string = ''
  @Provide() public pid: string = getCurrentQuery('pid')
  @Provide() public pactDialog: boolean = false
  @Provide() public apiError: string = ''
  @Provide() channelTag = ''
  @Provide() storeTag = ''

  public async created () {
    this.p = getCurrentQuery('p')
    this.smsType = getCurrentQuery('smsType') ? parseInt(getCurrentQuery('smsType')) : 3
    this.grantType = getCurrentQuery('grantType') ? parseInt(getCurrentQuery('grantType')) : 4
    this.storeCode = getCurrentQuery('storeCode') || ''
    this.mobile = getCurrentQuery('phone') || ''
    this.channelTag = getCurrentQuery('ct') || ''
    this.storeTag = getCurrentQuery('st') || ''
    this.refresh()
  }

  async refresh () {
    try {
      const res = await httpHelper.checkUnionLoginStatus({
        channelTag: this.channelTag,
        storeTag: this.storeTag
      })
      console.log(res)
      if (res.value && res.value.extend && res.value.extend && res.value.extend.gotoUrl) {
        location.replace(res.value.extend.gotoUrl)
      }
    } catch (err) {
      console.log(err)
    }
  }

  @Emit()
  public async sendCode () {
    this.apiError = this.mobileError()
    if (this.apiError) {
      return
    }
    if (!this.policySelect) {
      this.pactDialog = true
      return
    }
    (this.$refs.verification as any).sendCode()
    this.isFirst = false
  }
  @Emit()
  public mobileError () {
    if (!this.mobile) {
      return '手机号码不能为空'
    }
    return mobileRegExp.test(this.mobile) ? '' : '手机号格式不正确，请重新输入'
  }

  @Emit()
  public goback () {
    console.log('goback')
    let url = getGoBackUrl()
    if (!this.isFirst) {
      console.log('goback1')
      this.dialog = true
    } else if (document.referrer.includes('userHome')) {
      console.log('goback2')
      location.replace(httpHelper.mRoot)
    } else if (document.referrer.includes('passport.benlai.com/authorization')) {
      console.log('goback3')
      history.go(-2)
    } else if (url.includes('userHome')) {
      console.log('goback6')
      removeGoBackUrl()
      location.replace(httpHelper.mRoot)
    } else if (url) {
      console.log('goback5')
      removeGoBackUrl()
      location.replace(url)
    } else {
      console.log('goback4')
      history.back()
    }
  }
  @Emit()
  public cancelBack () {
    this.dialog = false
  }

  @Emit()
  public cofrimBack () {
    this.isFirst = true
    this.dialog = false
  }

  @Emit()
  public setValue (detail: any) {
    this.mobile = detail.value
  }

  @Emit()
  public toLogin () {
    this.$router.replace(`/login${location.search}`)
  }

  @Emit()
  public selectPolicy () {
    this.policySelect = !this.policySelect
  }

  public agree () {
    this.pactDialog = false
    this.policySelect = true
    this.sendCode()
  }
  public tourist () {
    this.pactDialog = false
  }
}

