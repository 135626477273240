
import { Component, Vue, Provide, Emit, Prop, Watch } from 'vue-property-decorator'
import { mobileRegExp } from '@/utils/reg'
import smsMixin from '@/mixins/sms'
import httpHelper from '@/utils/request'
import {
  getYiqianbaoAuthCode,
  getYiqianbaoSource,
  getCCBId,
  setCCBSchoolId,
  setLiangChuAuth,
  getGATId,
  setGATUserId,
  getCCBNJInfo,
  setCCBNJInfo,
  setCCBNJcampusId,
  setSinoepcAuth,
  getSinoepcInfo,
  getXFTData,
  getXFTSign
} from '@/utils/cookies'
import configs from '@/configs'
import GlobalMixin from '@/mixins/global'
import randomString from '@/utils/random'
import { getGoBackUrl } from '@/utils/gobackurl'
import { parseSearch } from '@/utils/env'
import getCurrentQuery from '@/utils/querystrings'
const CryptoJS = require('crypto-js')
// console.log(AES)

@Component({
  name: 'VerificationCode',
  components: {
  },
  mixins: [smsMixin, GlobalMixin]
})

export default class VerificationCode extends Vue {
  public name = 'VerificationCode'

  @Prop({
    type: String,
    default: ''
  }) mobile!: string

  @Prop({
    type: String,
    default: ''
  }) times!: string
  @Prop({
    type: Number,
    default: 4
  }) grantType!: number
  @Prop({
    type: Number,
    default: 3
  }) smsType!: number

  @Provide() public code: string = ''
  @Provide() public codeArr: any = []
  @Provide() public errorMsg: string = ''
  @Provide() public lastMobile: string = ''
  @Provide() public loding: boolean = false
  @Provide() channelTag = ''
  @Provide() storeTag = ''

  public async created () {
    this.channelTag = getCurrentQuery('ct') || ''
    this.storeTag = getCurrentQuery('st') || ''
  }

  @Emit()
  public async input () {
    await this.login()
  }
  @Emit()
  public async login () {
    this.codeArr = String(this.code).split('') || []
    if (this.codeArr.length === 6) {
      if (this.loding) {
        return false
      }
      this.loding = true
      let type = 'Auth_PhoneSms'
      if (this.grantType !== 5 && this.grantType !== 6 && this.grantType !== 7 && this.grantType !== 8 && this.grantType !== 15 && this.grantType !== 11 && this.grantType !== 18 && this.grantType !== 16) {
        const data:any = {
          grant_type: type,
          userName: this.mobile,
          smsCode: this.code,
          channelTag: this.channelTag,
          storeTag: this.storeTag
        }
        if (this.grantType === 4) {
          let iv = randomString(16)
          // let iv = 'P67P2jdDrNCpzQXt'
          const time = Math.round(Date.now() / 1000)
          const message = `${this.mobile}|${time}`
          // const message = '15858585858|1637056682'
          // const key = 'k*^JjnSlZ&3Ro&w+fj%glN7%ITU9FLav'
          const key = CryptoJS.enc.Utf8.parse('k*^JjnSlZ&3Ro&w+fj%glN7%ITU9FLav')
          data.salt = iv
          iv = CryptoJS.enc.Utf8.parse(iv)
          const encrypted = CryptoJS.AES.encrypt(message,
            key,
            {
              iv: iv,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7
            })
          data.userName = encrypted.toString()
        }
        try {
          const rediUrl = await httpHelper.requestUnionSMSToken(data)
          const { gotoUrl = '' } = rediUrl.value.extend
          const query = gotoUrl.toString().indexOf('?') > 0 ? `&url=${encodeURIComponent(location.href)}` : `?url=${encodeURIComponent(location.href)}`
          console.log(`${gotoUrl}${query}`)
          location.replace(`${gotoUrl}${query}`)
          return
        } catch (err) {
          this.code = ''
          this.codeArr = []
          this.goTo(err, this.smsType, this.grantType)
          this.errorMsg = err.msg
        }
      }
      this.loding = false
    }
  }
  @Emit()
  public async sendCode (isToast:number = 0) {
    let mobile = this.mobile
    if (this.smsType !== 7 && this.smsType !== 8) {
      this.errorMsg = this.mobileError()
      if (this.errorMsg) {
        return
      }
    } else {
      mobile = ''
    }
    let isSecend = 0
    if (this.lastMobile === mobile) {
      isSecend = 1
    }
    this.lastMobile = mobile
    const [err, res] = await this.sendSMSCode(mobile, this.smsType, isSecend)
    if (err && err.code && err.msg) {
      this.errorMsg = err.msg
    } else if (isToast === 1) {
      this.$toasted.show('验证码获取成功！')
    }
  }
  @Emit()
  public mobileError () {
    if (!this.mobile) {
      return '手机号码不能为空'
    }
    return mobileRegExp.test(this.mobile) ? '' : '手机号格式不正确，请重新输入'
  }
}
