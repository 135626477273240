
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import configs from '@/configs'
import { getUnionAuth, setUnionAuth } from '@/utils/cookies'

@Component({
  name: 'CiticLife',
  mixins: [GlobalMixin]
})
export default class CiticLifePage extends Vue {
  @Provide() public ischeck: boolean = false
  @Provide() public showLogin: boolean = false
  @Provide() channelTag = ''
  @Provide() storeTag = ''
  @Provide() thirdParams = {}

  async created () {
    this.channelTag = getCurrentQuery('ct') || ''
    this.storeTag = getCurrentQuery('st') || ''
    const thirdParams = getCurrentQuery('')
    for (let key in thirdParams) {
      if (['ct', 'st', 'needauthorize'].indexOf(key) > -1) {
        continue
      }
      this.thirdParams = {
        ...this.thirdParams,
        [key]: thirdParams[key]
      }
    }
    if (getUnionAuth() || getCurrentQuery('needauthorize') === '1') {
      this.doAuth()
    } else {
      this.showLogin = true
    }
  }

  @Emit()
  public select () {
    this.ischeck = !this.ischeck
  }

  @Emit()
  public next () {
    if (!this.ischeck) {
      this.$toasted.show('勾选下方同意后，才可去购物哦~')
      return false
    }
    setUnionAuth()
    this.doAuth()
  }

  @Emit()
  public async doAuth () {
    const data: any = {
      channelTag: this.channelTag,
      storeTag: this.storeTag,
      extendParamsJson: JSON.stringify(this.thirdParams)
    }
    // debugger
    try {
      const res = await httpHelper.requestUnionLoginToken(data)
      console.log(res.value.extend.gotoUrl)
      location.replace(res.value.extend.gotoUrl)
    } catch (err) {
      this.goTo(err, 9, 105)
      this.$toasted.show(err.msg || err.message)
    }
  }
}
